<template>
  <aside  class="main-sidebar sidebar-dark-primary elevation-4" style="background-color: #1a477f;">
    <div style="background-color: #fff;">
      <a href="#" class="brand-link logo-switch">
        <img :src="imglink('logo_.png')"  class="brand-image-xl " > <span style="color: rgba(0,0,0,.7)">{{menuName}}</span>
        <!--      <img src="./src/assets/logo.png" alt="AdminLTE Docs Logo Large" class="brand-image-xs logo-xl" style="left: 12px">-->
      </a>
    </div>
    <div  class="sidebar os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition"><div class="os-resize-observer-host observed"><div class="os-resize-observer" style="left: 0px; right: auto;"></div></div>
      <div class="os-size-auto-observer observed" style="height: calc(100% + 1px); float: left;">
        <div class="os-resize-observer"></div></div><div class="os-content-glue" style="margin: 0px -8px; width: 249px; height: 10px;"></div>
      <div class="os-padding">
        <div class="os-viewport os-viewport-native-scrollbars-invisible">
          <div class="os-content" style="padding: 0px 8px; height: 100%; width: 100%;">

      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu">
          <li class="nav-item" v-if="this.roleId === 1">
            <router-link to="home"  class="nav-link">
              <i class="nav-icon fa fa-sync"></i>
              <p @click.prevent="reFresh">
                refresh Menu
              </p>
            </router-link>
          </li>
          <li  v-for="data in menu"  v-bind:key="data.menuId" class="nav-item">
            <router-link :to="data.menu_url" class="nav-link" v-show="MenuRole(data.role_id)&& MenuCompany(data.companyId)">
              <i :class="data.icon"></i>
              {{ data.menuName }}
              <i class="right fas fa-angle-left"></i>
            </router-link>
            <ul class="nav nav-treeview" v-if="data.submenus.length > 0">
                <li class="nav-item" v-for="submenu in data.submenus"  v-bind:key="submenu.SubmenuId">
                  <router-link :to="submenu.menu_url" class="nav-link" v-show="MenuRole(submenu.role_id) && MenuCompany(submenu.companyId)">
                    {{ submenu.SubmenuName }}
                  </router-link>
                </li>
            </ul>

          </li>
        </ul>
      </nav>
    </div>
        </div>
      </div>
      <div class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
        <div class="os-scrollbar-track"><div class="os-scrollbar-handle" style="width: 100%; transform: translate(0px, 0px);"></div>
        </div>
      </div>
      <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable os-scrollbar-auto-hidden">
        <div class="os-scrollbar-track">
          <div class="os-scrollbar-handle" style="height: 100%; transform: translate(0px, 0px);"></div>
        </div></div>
      <div class="os-scrollbar-corner"></div>
    </div>
  </aside>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
export default {
  components: {},

  data(){
    return{
      toggle:'',
      menuName: 'ธีระการแว่น',
    }
  },
  computed: {
    ...mapGetters(["menu","roleId","companyId",'isMenu',"isLoggedIn"]),

  },
   mounted() {
     this.getMenus()
  },
  methods:{
    ...mapActions(['getMenu','refresh','checkAuth']),
    async getMenus(){
        if (!this.isMenu && this.isLoggedIn){
          await this.getMenu();
        }

    },
     MenuRole(data){
       if (data.includes(this.$store.getters.roleId.toString())){
         return true
       }else {
         return false
       }
    },
     MenuCompany(data){
       if (data.includes(this.$store.getters.companyId.toString())){
         return true
       }else {
         return false
       }
    },
    reFresh(){
      this.refresh();
    },
    imglink: function(data) {
      return require("@/assets/"+data);
    }
  }
}

</script>

<style scoped>

</style>