<template>
  <div :class = "isLoggedIn? 'wrapper': '' " id="app">
    <Navbar v-if="isLoggedIn"/>
    <Menu v-show="isLoggedIn"/>
    <div :class = "isLoggedIn? 'content-wrapper px-4 py-2': 'text-center'">
<!--  <div  id="app">-->
<!--    <Navbar />-->
<!--    <Menu />-->
<!--    <div>-->
      <ContentHeader  v-show="isLoggedIn" :msg="this.$route.name"/>
      <router-view/>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex'
import Navbar from "@/components/Navbar";
import Menu from "@/components/Menu";
import ContentHeader from "@/components/ContentHeader";
export default {
  components: {
    Navbar,
    Menu,
    ContentHeader
  },
  computed: {
    ...mapGetters([
        "isLoggedIn","isMenu"
    ]),

  },
  mounted() {
    this.getResults()
  },
  data(){
    return{
      toggle:'',
    }
  },
  methods:{
    ...mapActions(['checkAuth']),
    async getResults(){
      if (this.isLoggedIn){
        console.log(this.router)
        await this.checkAuth();
      }
    },
  },
}

</script>
<style scoped>

html, body {
  font-family: 'Kanit', sans-serif;
}

#app {
  font-family: 'Kanit', sans-serif;
}
</style>
