import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ("./css/css")


import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)





// Vue.prototype.$axios = axios
// window.axios = require('axios');

Vue.prototype.$http = axios;
// Load the token from the localStorage
const token = localStorage.getItem("token");
// Is there is any token then we will simply append default axios authorization headers
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
  axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT || "http://localhost:3000"
}else {
  Vue.config.productionTip = false
  // axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT || "http://localhost:8000"
  axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT || "https://keeplookoptical.com"
}



import VueSweetalert2 from 'vue-sweetalert2';
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import MultiSelect from "vue-multiselect";
Vue.component('multi-select',MultiSelect)

Vue.use(Vuelidate)

// Vue.use(window.Vuelidate.default);

import moment from 'moment';
Vue.filter('myDate',function(created){
  return moment(created).locale('th').format('Do MMMM YYYY');
});
Vue.filter('myDateThs',function(created){
  return moment(created, "YYYY-MM-DD").add(543, 'years').locale('th').format('ll');
});

Vue.filter('myDateThshort',function(created){
  return moment(created, "YYYY-MM-DD").add(543, 'years').locale('th').format('DD/MM/YY');
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
