import axios from 'axios';
import router from '../router';
import ls from 'localstorage-slim';
import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import secret from '../config/dev.env'

ls.config.encrypt = true
ls.config.secret = secret.secret
ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
ls.config.decrypter = (data, secret) => {
    try {
        return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
    } catch (e) {
        // incorrect/missing secret, return the encrypted data instead
        return data;
    }
};

const state = {
    token: localStorage.getItem('token') || '',
    menu: ls.get('_m',{ decrypt: true }) || '',
    userId: ls.get('_u',{ decrypt: true }) || '',
    roleId: ls.get('_r',{ decrypt: true }) || '',
    companyId: ls.get('_s',{ decrypt: true }) || '',
    status: '',
    menuStatus: '',
    error: null,
    custData: ls.get('_c',{ decrypt: true }) || '',
};

const getters = {
    // isLoggedIn: function (state) {
    //     if (state.token != '') {
    //         return true
    //     } else {
    //         return false
    //     }
    // }
    isLoggedIn: state => !!state.token,
    // isLoggedIn: state => state.token,
    authState: state => state.status,
    userId: state => state.userId,
    roleId: state => state.roleId,
    companyId: state => state.companyId,
    error: state => state.error,
    isMenu: state => !!state.menu,
    menu: state => state.menu,
    menuError: state => state.menuError,
    custData: state => state.custData,
};

const actions = {
    // Login Action
    async login({
                    commit
                }, user) {
        commit('auth_request');
        commit('menu_request');
        try {
            let users = await axios.post('api/user/login', user)
            let menu = await axios.get('/api/menu/getMenu')
            if (users.data.success === true) {
                // Set the axios defaults
                const token = users.data.token;
                axios.defaults.headers.common['Authorization'] = token;
                const menus = menu.data.menu;
                // const user = res.data.user.userId;
                // Store the token into the localstorage
                await localStorage.setItem('token', token);
                await ls.set('_u', users.data.user.userId,{encrypt: true});
                await ls.set('_r', users.data.user.roleId,{encrypt: true});
                await ls.set('_s', users.data.user.companyId,{encrypt: true});
                await ls.set('_m', menus,{encrypt: true});

                await commit('menu_success', menus);
                await commit('auth_success',users.data);
                return users;
            }

        } catch (err) {
            commit('auth_error', err);
            commit('menu_error', err);
        }
    },
    // Register User
    async register({
                       commit
                   }, userData) {
        try {
            commit('register_request');
            let res = await axios.post('/api/users/register', userData);
            if (res.data.success !== undefined) {
                commit('register_success');
            }
            return res;
        } catch (err) {
            commit('register_error', err)
        }
    },

    async checkAuth({
                       commit
                   }) {
            axios.get('/api/user/checkAuth')
                .then( async (res) => {
                if (res.data.status !== true) {
                    await localStorage.clear();
                    await commit('logout');
                    delete axios.defaults.headers.common['Authorization'];
                    await router.push('/login');
                }})

    },
    // Get the user Profile
    async getProfile({
                         commit
                     }) {
        commit('profile_request');
        let res = await axios.get('/api/users/profile')
        commit('user_profile', res.data.user)
        return res;
    },
    // Logout the user
    async logout({
                     commit
                 }) {
        await localStorage.clear();
        await commit('logout');
        await delete axios.defaults.headers.common['Authorization'];
        await router.push('/login');
    },
    // menu
    async getMenu({
                      commit
                  }) {
        commit('menu_request');
        try {
            let res = await axios.get('/api/menu/getMenu')
            if (res.data.success === true) {
                const menu = res.data.menu;
                // localStorage.setItem('_m', JSON.stringify(menu));
                await ls.set('_m', menu,{encrypt: true});
                await commit('menu_success', menu);
                return menu;
            }
        } catch (err) {
            commit('menu_error', err);
        }
    },
    // refresh the menu
    async refresh({
                      commit
                  }) {
        try {
            commit('menu_refresh');
            commit('menu_request');
            // ls.remove('_m');
            let res = await axios.get('/api/menu/getMenu')
            if (res.data.success && res.status === 200) {
                const menu = res.data.menu;
                ls.remove('_m');
                await ls.set('_m', menu,{encrypt: true});
                // await localStorage.removeItem('_m');
                // await localStorage.setItem('menu', menu);
                await commit('menu_success', menu);
            }

        } catch (err) {
            commit('menu_error', err);
        }
    },


};

const mutations = {

    auth_request(state) {
        state.error = null
        state.status = 'loading'
    },
    auth_success(state, data) {
        state.token = data.token
        state.userId = data.user.userId
        state.roleId = data.user.roleId
        state.companyId = data.user.companyId
        state.status = 'success'
        state.error = null
    },
    auth_error(state, err) {
        state.error = err.response.data.msg
    },
    register_request(state) {
        state.error = null
        state.status = 'loading'
    },
    register_success(state) {
        state.error = null
        state.status = 'success'
    },
    register_error(state, err) {
        state.error = err.response.data.msg
    },
    logout(state) {
        state.error = null
        state.status = ''
        state.token = ''
        state.userId = ''
        state.roleId = ''
        state.companyId = ''
        state.menuError = null
        state.menuStatus = ''
        state.menu = ''
    },
    profile_request(state) {
        state.status = 'loading'
    },
    user_profile(state, user) {
        state.user = user
    },
    menu_request(state) {
        state.menuError = null
        state.status = 'loading'
    },
    menu_success(state, menu) {
        state.menu = menu,
        state.menuStatus = 'success'
    },
    menu_error(state,err) {
        state.menuError = err
    },
    menu_refresh(state) {
        state.menuError = null
        state.menuStatus = ''
        state.menu = ''
    },

    async set_custdata(state,custData) {
        // console.log(custData)
        await ls.set('_c', custData,{encrypt: true});
        state.custData = custData
        // console.log(state.custData)
    },
    async clear_custdata(state) {
        await localStorage.removeItem('_c');
        state.custData = ''
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};