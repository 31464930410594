<template>
  <!-- Navbar -->
  <nav  class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" @click.prevent="logoutUser" role="button"><i
            class="fas fa-sign-in-alt"></i></a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>
<script>
import { mapActions } from "vuex";
export default {
  data(){
    return{
      isLoggedIn:'',
      toggle:'',
    }
  },
  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.logout();
    }
  }
}
</script>

<style>

</style>