import Vue from 'vue'
import Router  from 'vue-router'
import store from '../store/index';

Vue.use(Router )

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true} },
    { path: '/home', name: 'home', component: () => import('../views/Home.vue'), meta: {requiresAuth: true} },
    { path: '/404', name: '404', component: () => import('../views/404.vue'),},
    // {path: '/:catchAll(.*)', redirect:'404'} ,
    // { path: '/:pathMatch(.*)*', name: '404', component: () => import('../views/404.vue') },
    { path: '/login', name: 'login', component: () => import('../views/Login.vue'), meta: {requiresGuest: true} },
    { path: '/about', name: 'about', component: () => import('../views/About.vue'), meta: {requiresAuth: true} },
    { path: '/usermsg', name: 'usermsg', component: () => import('../views/User.vue'), meta: {requiresAdmin: true} },
    { path: '/customers', name: 'customers', component: () => import('../views/Customers.vue'), meta: {requiresAuth: true} },
    { path: '/customercreate', name: 'CustomerCreate', component: () => import('../views/CustomerCreate.vue'), meta: {requiresAuth: true} },
    { path: '/prefix', name: 'prefix', component: () => import('../components/Prefix.vue'), meta: {requiresSuperAdmin: true} },
    { path: '/district', name: 'district', component: () => import('../components/District.vue'), meta: {requiresSuperAdmin: true} },
    { path: '/amphurs', name: 'amphurs', component: () => import('../components/Amphurs.vue'), meta: {requiresSuperAdmin: true} },
    { path: '/province', name: 'province', component: () => import('../components/Province.vue'), meta: {requiresSuperAdmin: true} },
    { path: '/customerdetail', name: 'customerdetail', component: () => import('../views/CustomerDetail.vue'), meta: {requiresAuth: true} },
    { path: '/customerreport', name: 'customerreport', component: () => import('../views/CustomersReport.vue'), meta: {requiresAuth: true} },
    { path: '/customerorderreport', name: 'customerorderreport', component: () => import('../views/CustomersOrderReport.vue'), meta: {requiresAuth: true} },
    { path: '/company', name: 'company', component: () => import('../views/Company.vue'), meta: {requiresAdmin: true} },

  ]
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/404');
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.isLoggedIn) {
        // Redirect to the Login Page
        next('/login');
      } else {
        next();
      }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
      if (store.getters.isLoggedIn) {
        // Redirect to the Login Page
        next('/home');
      } else {
        next();
      }
    } else if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
      if (store.getters.isLoggedIn && store.getters.roleId !== 1) {
        // Redirect to the Login Page
        next('/404');
      } else {
        next();
      }
    } else if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (store.getters.isLoggedIn) {
        if (store.getters.roleId === 1 || store.getters.roleId === 2){
          next();
        }else {
          next('/404');
        }
      } else {
        next('/404');
      }
    }else {
      next()
    }
  }
});

export default router;

